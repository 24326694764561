import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

import Layout from '../components/layout'
import Footer from '../components/footer'
import Copyright from '../components/copyright'
import SEO from '../components/seo'
import Header from '../components/header'
import Container from '../components/container'
import CenterBlock from '../components/center-block'
import Image from '../components/images/parties'

import DeforestContract from '../forms/Deforest-Party-Contract_2024-10.pdf'
import CottageGroveContract from '../forms/Cottage-Grove-Party-Contract_2025-03.pdf'

import '../css/parties.css'

const PartiesPage = () => (
  <Layout>
    <SEO
      title="Parties | Jump Around Gymnastics"
      keywords={[
        'jump around gymnastics',
        'birthday party',
        'birthday celebration',
        'gymnastics party',
        'gymnastics madison',
        'gymnastics cottage grove',
        'gymnastics deforest',
        'gymnastics sun prairie',
        'gymnastics fall river',
        'gymnastics pardeeville',
      ]}
      description={`Our parties are fun, safe, and hassle-free! Kids enjoy guided activities, free play, and then can retreat to the party room for cake and presents!`}
    />
    <Header>
      <div className="w-full py-16 px-2 text-center text-white text-shadow">
        <h1 className="text-5xl text-display uppercase">Parties</h1>
        <p className="my-8 text-2xl text-display tracking-wide">
          Schedule Your Event Today
        </p>
        <div className="w-full lg:w-1/2 mx-auto flex flex-col">
          <OutboundLink
            className="bg-orange-dark hover:bg-orange text-white uppercase font-bold py-3 px-16 mb-6 w-full lg:w-auto rounded-full no-underline text-xl shadow-md hover:shadow-lg text-shadow transition-all"
            href="tel:1-608-846-5867"
          >
            DeForest <br className="md:hidden" />
            (608) 846&#45;5867
          </OutboundLink>
          <br className="" />
          <OutboundLink
            className="bg-orange-dark hover:bg-orange text-white uppercase font-bold py-3 px-16 w-full lg:w-auto rounded-full no-underline text-xl shadow-md hover:shadow-lg text-shadow transition-all"
            href="tel:1-608-622-5254"
          >
            Cottage Grove <br className="md:hidden" />
            (608) 622&#45;5254
          </OutboundLink>
        </div>
      </div>
    </Header>
    <Container>
      <div className="my-16 ">
        <CenterBlock>
          <h1 className="font-light mb-4">Celebrate In Style!</h1>
          <p className="text-grey-darker">
            At Jump Around Gymnastics, you can throw a birthday party that your
            child will never forget! Our coaches and professional staff ensure
            the safety of everyone while they have endless fun exploring our
            gymnastics equipment and participating in guided activities.
          </p>
        </CenterBlock>
      </div>
      <div className="flex flex-wrap mt-24 mb-16">
        <div className="w-full lg:w-1/2 mb-12 my-8">
          <Image
            src="partiesImage"
            aspectRatio="fluid"
            className="picture-frame rotate-left w-4/5 m-auto"
            alt="Young girl smiling and laughing, jumping into foam pit"
          />
        </div>
        <div className="w-full lg:w-1/2 lg:pl-4 mt-12 lg:mt-0 px-4 lg:px-0">
          <div className="rounded border-4 border-dashed border-platinum p-4 bg-white shadow-md">
            <h2 className="pb-4 text-display text-4xl bg-gradient-platinum text-bg-clip">
              Platinum
              <span className="float-right circle bg-gradient-platinum -mt-8 text-white border-4 border-white shadow text-shadow">
                $350
              </span>
            </h2>
            <p className="text-grey-darker">
              This party includes one hour of private gym time with guided
              activities, plus a full hour in our private party room. You may
              bring your own food, cake, and drinks.
            </p>
            <p className="text-grey-darker mt-4 italic">
              * May exceed 10 children. ($10.00 per additional participant)
            </p>
          </div>
          <div className="rounded border-4 border-dashed border-gold p-4 bg-white mt-12 shadow-md">
            <h2 className="pb-4 text-display text-4xl bg-gradient-yellow text-bg-clip">
              Gold
              <span className="float-right circle bg-gradient-yellow -mt-8 text-white border-4 border-white shadow text-shadow">
                $300
              </span>
            </h2>
            <p className="text-grey-darker">
              This party includes one hour of private gym time with guided
              activities, plus a half hour in one of our private party rooms.
              You may bring your own food, cake, and drinks.
            </p>
            <p className="text-grey-darker mt-4 italic">
              * May exceed 10 children. ($10.00 per additional participant)
            </p>
          </div>
          <div className="rounded border-4 border-dashed border-silver p-4 bg-white mt-12 shadow-md">
            <h2 className="pb-4 text-display text-4xl bg-gradient-silver text-bg-clip">
              Silver
              <span className="float-right circle bg-gradient-silver -mt-8 text-white border-4 border-white shadow text-shadow">
                $250
              </span>
            </h2>
            <p className="text-grey-darker">
              This party includes one hour of private gym time with guided
              activities.
            </p>
            <p className="text-grey-darker mt-4 italic">
              * May exceed 10 children. ($10.00 per additional participant)
            </p>
          </div>
          <div className="rounded border-4 border-dashed border-bronze p-4 bg-white mt-12 shadow-md">
            <h2 className="pb-4 text-display text-4xl bg-gradient-bronze text-bg-clip">
              Bronze
              <span className="float-right circle bg-gradient-bronze -mt-8 text-white border-4 border-white shadow text-shadow">
                $200
              </span>
            </h2>
            <p className="text-grey-darker">
              This party is during Open Jump and includes 30 minutes in the
              private party room.
            </p>
            <p className="text-grey-darker mt-4 italic">* Max of 10 children</p>
          </div>
        </div>
      </div>
    </Container>
    <section className="bg-white">
      <Container>
        <div className="flex flex-wrap py-16">
          <div className="w-full px-4 lg:px-0">
            <h1 className="font-light">The Details</h1>
            <p className="text-grey-darker mt-4">
              We require the full payment and a completed birthday party
              contract in order to book your party day and time. There will be a
              charge of $10.00 per child if your group is over 10 children.
            </p>
            <p className="text-grey-darker mt-4">
              Each child that attends the party will need to have a waiver form
              completed by their parent.
            </p>
            <p className="text-grey-darker font-bold mt-4">
              Please contact us to schedule your event{' '}
              <span className="uppercase">today</span>! Dates fill quickly so
              don&rsquo;t delay!
            </p>
          </div>
          <div className="flex flex-col w-full lg:w-1/2 px-4 mx-auto">
            <OutboundLink
              href={DeforestContract}
              className="button-orange"
              target="_blank"
              rel="noopener noreferrer"
            >
              DeForest Contract
            </OutboundLink>
            <div className="w-full -mt-12">
              <OutboundLink
                href={CottageGroveContract}
                className="button-orange w-full flex justify-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cottage Grove Contract
              </OutboundLink>
            </div>
            <div className="w-full -mt-12">
              <OutboundLink
                href="https://docs.google.com/forms/d/e/1FAIpQLSdiYSBWsZlbiaiKUftzYgfr-lxzPma_0LtCWAKGrZKad4PPEw/viewform?vc=0&c=0&w=1"
                className="button-orange w-full flex justify-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                Waiver
              </OutboundLink>
            </div>
          </div>
        </div>
      </Container>
    </section>
    <Footer></Footer>
    <Copyright></Copyright>
  </Layout>
)

export default PartiesPage
